//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkSkeleton',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
});
